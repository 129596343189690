<template>
  <v-container>
    <v-form ref="createForm" v-model="checkValidate" :lazy-validation="lazy">
      <v-row align="center" justify="center" no-gutters>
        <v-col cols="12">
          <h1>เพิ่มทะเบียนประกาศ</h1>
        </v-col>

        <!-- <v-col cols="12">
          <v-row>
            <v-col cols="12" md="3">
              <span>เลขที่หนังสือ</span>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                v-model="data.bookNo"
                label="เลขที่หนังสือ"
                outlined
                dense
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-col> -->

        <!-- <v-col cols="12">
          <v-row>
            <v-col cols="12" md="3">
              <span>วันที่ลงส่งหนังสือ</span>
            </v-col>

            <v-col cols="12" md="4">
              <v-menu
                v-model="menufrom"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="data.dateFrom"
                    label="วันที่ลงส่งหนังสือ"
                    append-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    dense
                    solo
                    clearable
                    @click:clear="data.dateFrom = null"
                  ></v-text-field>
                </template>
                <v-date-picker
                  no-title
                  v-model="data.dateFrom"
                  @input="menufrom = false"
                  @click="$refs.menufrom.save(data.dateFrom)"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
        </v-col> -->

        <v-col cols="12">
          <v-row>
            <v-col cols="12" md="3">
              <span>วันที่ออกประกาศ</span>
            </v-col>
            <v-col cols="12" md="4">
              <v-menu
                v-model="menuto"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="data.dateTo"
                    label="วันที่ออกประกาศ"
                    append-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    dense
                    solo
                    clearable
                    @click:clear="data.dateTo = null"
                  ></v-text-field>
                </template>
                <v-date-picker
                  no-title
                  v-model="data.dateTo"
                  @input="menuto = false"
                  @click="$refs.menuto.save(data.dateTo)"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12">
          <v-row>
            <v-col cols="12" md="3">
              <span>หน่วยงาน</span>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                v-model="data.agency"
                label="หน่วยงาน"
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row>
        </v-col>
        <!-- <v-col cols="12">
        <v-row>
          <v-col cols="12" md="3">
            <span>ถึง</span>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field v-model="data.formTo" label="ถึง" outlined dense></v-text-field>
          </v-col>
        </v-row>
      </v-col> -->
        <v-col cols="12">
          <v-row>
            <v-col cols="12" md="3">
              <span>เรื่อง</span>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                v-model="data.subject"
                label="เรื่อง"
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12">
          <v-row>
            <v-col cols="12" md="3">
              <span>ผู้ลงนามสั่งการ</span>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                v-model="data.orderWhoName"
                label="ผู้ลงนามสั่งการ"
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12">
          <v-row>
            <v-col cols="12" md="3">
              <span>การปฏิบัติ</span>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                v-model="data.practice"
                label="การปฏิบัติ"
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12">
          <v-row>
            <v-col cols="12" md="3">
              <span>แนบไฟล์</span>
            </v-col>
            <v-col cols="12" md="4">
              <input
                type="file"
                accept="application/pdf"
                id="file"
                ref="file"
                v-on:change="handleFileUpload()"
              />
              <span style="color: red"
                >สามารถอัพโหลดเฉพาะไฟล์ .pdfเท่านั้น</span
              ></v-col
            >
            <v-col cols="12" md="4">
              <v-btn @click="submitFile()">อัพโหลด</v-btn>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" class="mt-4">
          <v-row>
            <v-col cols="12" md="3">
              <span>หมายเหตุ</span>
            </v-col>
            <v-col cols="12" md="9">
              <v-textarea
                v-model="data.remark"
                label="หมายเหตุ"
                outlined
                dense
              ></v-textarea>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12">
          <v-row>
            <v-btn @click="back()">ยกเลิก</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="#0074AA" dark @click="submit()">บันทึก</v-btn>
          </v-row>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>
<script>
import { Decode, Endcode } from "@/services";
export default {
  data() {
    return {
      menufrom: false,
      menuto: false,
      type: "",
      data: {
        year: "",
        // bookNo: "",
        // dateFrom: '',
        // bookType: "",
        // speed: "",
        dateTo: '',
        agency: "",
        // formTo:"",
        subject: "",
        orderWhoName: "",
        practice: "",
        file: "",
        remark: "",
        userId: "",
        type: "form3",
      },
      bookTypeItems: [
        "หนังสือภายนอก",
        "หนังสือภายใน",
        "หนังสือประทับตรา",
        "หนังสือสั่งการ",
        "หนังสือประชาสัมพันธ์",
        "หนังสือที่เจ้าหน้าที่ทำขึ้นหรือรับไว้เป็นหลักฐานในราชการ",
      ],
      speedItems: ["ปกติ", "ด่วน", "ด่วนมาก", "ด่วนที่สุด"],
      checkValidate: true,
      lazy: false,
    };
  },
  created() {
    this.data.year = JSON.parse(
      Decode.decode(localStorage.getItem("yearSelected"))
    );
    var checklogin = JSON.parse(
      Decode.decode(localStorage.getItem("sarabankhokklang"))
    );
    this.data.userId = checklogin.user.id;
    console.log("checklogin", checklogin.user.id);
  },
  methods: {
    async handleFileUpload() {
      this.data.file = this.$refs.file.files[0];
      console.log("file", this.data.file);
    },
    async submitFile() {
      this.loading = true;
      let formData = new FormData();
      formData.append("files", this.data.file);
      formData.append("type", this.type);
      formData.append("userId", this.data.userId);
      console.log("formData", formData);
      const auth = {
        headers: {
          "Content-Type": "multipart/form-data",
          // Authorization: `Bearer` + " " + this.checkuser.token,
        },
      };
      console.log("auth", auth);
      this.uploaded = true;
      const response = await this.axios.post(
        `${process.env.VUE_APP_API}/files/formData`,
        formData,
        auth
      );
      // .then(function () {
      console.log("SUCCESS!!");
      console.log("response", response);
      this.uploaded = false;
      if (response.data.response_status == "SUCCESS") {
        this.loading = false;
        await this.$swal.fire({
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true,
          icon: "success",
          text: "อัพโหลดสำเร็จ",
        });
        this.data.file = response.data.data.path;
      } else {
        this.loading = false;
        await this.$swal.fire({
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true,
          icon: "error",
          text: "อัพโหลดไฟล์ไม่สำเร็จ",
        });
      }

      // })
      // .catch(function () {
      //   console.log("FAILURE!!");
      // });
    },
    async submit() {
      // if (this.data.file == "") {
      //   this.$swal.fire({
      //     icon: "error",
      //     text: `กรุณาอัพโหลดไฟล์`,
      //     showConfirmButton: false,
      //     timer: 1500,
      //   });
      // }
      if (this.$refs.createForm.validate(true)) {
        var user = JSON.parse(
          Decode.decode(localStorage.getItem("sarabankhokklang"))
        );
        const auth = {
          headers: { Authorization: `Bearer ${user.token}` },
        };
        // if (!this.data.dateFrom) {
        //           this.data.dateFrom = null;
        //         }

        if (!this.data.dateTo) {
          this.data.dateTo = null;
        }
        const response = await this.axios.post(
          `${process.env.VUE_APP_API}/forms/form3`,
          this.data,
          auth
        );
        console.log("createCate", response);
        console.log(response.data.response_status);
        if (response.data.response_status == "SUCCESS") {
          this.$swal.fire({
            icon: "success",
            text: `บันทึกข้อมูลสำเร็จ`,
            showConfirmButton: false,
            timer: 1500,
          });
          this.$router.push("ManageForm3");
        } else {
           this.$swal.fire({
            icon: "error",
            text: response.data.response_description,
            showConfirmButton: false,
            timer: 1500,
          });
        }
      }
    },
    back() {
      this.$router.push("ManageForm3");
    },
  },
};
</script>
